html { scroll-behavior: smooth; }

.box {
  height: 90vh;
  background-position: center center;
  background-size: cover;
  margin: 0;
  padding: 0;
  border: 0;
  display: flex;
  align-items: center;
}

button.mercadopago-button {
  background-color: transparent;
  color: #35647D;
  border: 1px solid rgba(50, 98, 149, 0.5);
  border-radius: 4px;

  padding: 3px 9px;
  font-size: 0.8125rem;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  
  text-transform: uppercase;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  justify-content: center;
  text-decoration: none;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-indent: 0px;
  text-shadow: none;
  text-align: center;
  font: 400 13.3333px Arial;
}

.animation {
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
	animation: beat .85s infinite alternate;
}


/* Heart beat animation */
@keyframes beat{
	to { transform: scale(1.3); }
}

.animated_2 {
  animation-duration: 2.5s;
  animation-iteration-count: infinite;
}

.tada {
  animation-name: tada;
}
@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }

  10%, 20% {
    transform: scale3d(.9, .9, .9) rotate3d(0, 0, 1, -3deg);
  }

  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}



.animation-pulse {
  animation: pulse 1.5s linear infinite;
}
@keyframes pulse {
  0% {
   transform: scale(1, 1);
  }

  50% {
   transform: scale(1.2, 1.2);
  }

  100% {
  transform: scale(1, 1);
  }
}